import axios from 'axios';

export default {
    state: {
        medicalReport: null,
        examTypes: [],
        examTypesFetched: false,
        selectedIcd: null,
        selectedExams: []
    },
    getters: {
    },
    mutations: {
        getMedicalReport(state, medicalReport) {
            state.medicalReport = medicalReport;
        },
        getExamTypes(state, examTypes) {
            state.examTypes = examTypes;
        },
        setExamTypesFetched(state, examTypesFetched) {
            state.examTypesFetched = examTypesFetched;
        },
        setIcd(state, icd) {
            console.log(icd);
            state.selectedIcd = icd;
        },
        selectExams(state, selectedExams) {
            state.selectedExams = selectedExams
        },
        removeExam(state, exam) {
            state.selectedExams.splice(state.selectedExams.indexOf(exam), 1);
        }
    },
    actions: {
        async getMedicalReport({ commit }, medicalReportId) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/doctor/shipcall/getMedicalReport/${medicalReportId}`);

                commit("getMedicalReport", res.data);
            } catch (e) {
                throw e;
            }
        },
        async autocomplete({ }, search) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/optionsList/icd10/en/${search}`);
                return res.data;
            } catch (e) {
                throw e;
            }
        },
        async getExamTypes({ commit, state }, refresh = false) {
            if (state.examTypesFetched && !refresh) return;

            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/optionsList/medExamTypes/en`);
                commit("getExamTypes", res.data);
                commit("setExamTypesFetched", true);
            } catch (e) {
                throw e;
            }
        },
        async getExams({ }, type) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/optionsList/medicalExaminations/en/${type}`);
                return res.data;
            } catch (e) {
                throw e;
            }
        },
        async submitMedicalReport({ commit }, medicalReport) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/doctor/shipcall/submitMedicalReport`, {
                    ...medicalReport
                });

                commit("toggleSnackbar", {
                    text: res.data.isFinal ? "Medical report has been submitted!" : "Medical report has been saved as draft!",
                    open: true,
                    color: "success"
                })

                commit("getMedicalReport", res.data);
            } catch (e) {
                throw e;
            }
        }
    },
}
