<template>
  <v-expansion-panels class="meta-card align-self-center">
    <v-expansion-panel>
      <v-expansion-panel-header
        color="grey lighten-3"
        class="font-weight-medium"
      >
        Case Information
      </v-expansion-panel-header>

      <v-expansion-panel-content color="grey lighten-5">
        <v-row class="pt-5">
          <v-col cols="6">
            <div class="subtitle-1 font-weight-medium">Seafarer name</div>
          </v-col>
          <v-col cols="6">
            <div class="body-1 text-end" v-if="medicalReport">
              {{ medicalReport.shipFarerName }}
            </div>
          </v-col>

          <v-col cols="6">
            <div class="subtitle-1 font-weight-medium">Seafarer SBN</div>
          </v-col>
          <v-col cols="6">
            <div class="body-1 text-end" v-if="medicalReport">
              {{ medicalReport.shipFarerSBN }}
            </div>
          </v-col>

          <v-col cols="6">
            <div class="subtitle-1 font-weight-medium">Ship name</div>
          </v-col>
          <v-col cols="6">
            <div class="body-1 text-end" v-if="medicalReport">
              {{ medicalReport.shipName }}
            </div>
          </v-col>

          <v-col cols="6">
            <div class="subtitle-1 font-weight-medium">Company Name</div>
          </v-col>
          <v-col cols="6">
            <div class="body-1 text-end" v-if="medicalReport">
              {{ medicalReport.shipOwnerName }}
            </div>
          </v-col>

          <v-col cols="6">
            <div class="subtitle-1 font-weight-medium">Videocall Date</div>
          </v-col>
          <v-col cols="6">
            <div class="body-1 text-end" v-if="medicalReport">
              {{ moment(medicalReport.shipCallDateStart).format("DD/MM/YYYY") }}
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      moment,
    };
  },
  computed: {
    ...mapState({
      medicalReport: (state) => state.medicalReport.medicalReport,
    }),
  },
};
</script>

<style lang="scss" scoped>
.meta-card {
  width: 350px;
}
</style>