<template>
  <div class="component-wrapper d-flex flex-column justify-center align-center">
    <EnterVideoCall v-if="!validationCalled && this.$route.query.token" />
    <!-- <EnterVideoCall v-if="!validationCalled || this.$route.query.token" /> -->

    <template v-else>
      <template v-if="isTokenValid">
        <div class="text-h4 primary--text text-center mb-2">
          Thank you for your services
        </div>

        <div
          class="body-1 grey--text text--darken-2 text-center"
          v-if="!callEnded"
        >
          To use this application, click on the link forwarded to you by sms or
          email.
        </div>
      </template>

      <template v-else>
        <div class="text-h4 primary--text text-center mb-2">
          The link is invalid or it has been expired.
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import EnterVideoCall from "@/components/EnterVideoCall";
import { mapState } from "vuex";

export default {
  components: {
    EnterVideoCall,
  },
  data() {
    return {
      loader: false,
    };
  },
  computed: {
    ...mapState({
      validationCalled: (state) => state.validation.validationCalled,
      isTokenValid: (state) => state.validation.isTokenValid,
      callEnded: (state) => state.room.callEnded,
    }),
  },
};
</script>
