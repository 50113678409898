<template>
  <div class="component-wrapper d-flex flex-column white">
    <v-btn icon class="mb-4" color="black" @click="$emit('closeDialog')">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <div class="inner-wrapper d-flex flex-column align-self-center flex-grow-1">
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="examTypesLoader"
      ></v-progress-linear>

      <v-slide-group show-arrows v-model="selectedTypeIndex" class="mb-12">
        <v-slide-item
          v-for="(examType, index) in examTypes"
          :key="index"
          v-slot="{ active, toggle }"
        >
          <v-btn
            class="mx-2"
            :input-value="active"
            active-class="primary white--text"
            depressed
            rounded
            @click="onGetExams(toggle)"
          >
            {{ examType.option_value }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>

      <div class="flex-grow-1 d-flex flex-column align-center">
        <div
          class="subtitle-1 font-weight-medium text-center"
          v-if="!vuexSelectedExams.length"
        >
          No examinations have been selected.
        </div>

        <div class="d-flex flex-wrap align-self-center">
          <div
            class="pa-1"
            v-for="exam in vuexSelectedExams"
            :key="exam.option_key"
          >
            <v-chip color="primary" close @click:close="removeExam(exam)">
              {{ exam.option_value }}
            </v-chip>
          </div>
        </div>

        <v-divider class="my-4 align-self-stretch"></v-divider>

        <div class="flex-grow-1 d-flex flex-column scrollable">
          <div
            class="text-h6 text-center ma-auto"
            v-if="!examTypesLoader && !examsLoader && !exams.length"
          >
            No examination category has been selected.
          </div>

          <v-progress-circular
            indeterminate
            color="primary"
            size="50"
            width="4"
            class="ma-auto"
            v-if="examsLoader"
          ></v-progress-circular>

          <div class="exams" v-else>
            <v-row v-for="exam in exams" :key="exam.option_key">
              <v-col cols="6" class="d-flex align-center justify-center">
                <v-checkbox
                  :key="exam.option_key"
                  v-model="selectedExams"
                  :value="exam"
                  hide-details
                  class="ma-0"
                  @change="selectExams"
                ></v-checkbox>
              </v-col>

              <v-col cols="6" class="d-flex align-center justify-start">
                <div class="body-1">{{ exam.option_value }}</div>
              </v-col>
            </v-row>
          </div>
        </div>

        <v-divider class="my-4 align-self-stretch"></v-divider>

        <v-btn
          color="primary"
          class="align-self-center"
          @click="$emit('closeDialog')"
        >
          done
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      examTypesLoader: false,
      selectedTypeIndex: null,
      examsLoader: false,
      exams: [],
      selectedExams: [],
    };
  },
  async created() {
    this.examTypesLoader = true;
    await this.getExamTypes();
    this.examTypesLoader = false;

    this.selectedExams = [...this.vuexSelectedExams];
  },
  computed: {
    ...mapState({
      examTypes: (state) => state.medicalReport.examTypes,
      vuexSelectedExams: (state) => state.medicalReport.selectedExams,
    }),
    selectedType() {
      return this.examTypes[this.selectedTypeIndex];
    },
  },
  methods: {
    ...mapActions(["getExamTypes", "getExams"]),
    ...mapMutations(["selectExams", "removeExam"]),
    async onGetExams(toggle) {
      toggle();

      if (this.selectedType) {
        this.examsLoader = true;
        const exams = await this.getExams(this.selectedType.option_key);
        this.exams = exams;
        this.examsLoader = false;
      } else {
        this.exams = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.component-wrapper {
  min-height: 100%;
}

.exams {
  width: 300px;
}
</style>