<template>
  <div class="component-wrapper d-flex flex-column white">
    <v-btn icon class="mb-4" color="black" @click="$emit('closeDialog')">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <div class="inner-wrapper d-flex flex-column align-self-center flex-grow-1">
      <v-text-field
        outlined
        label="Search ICD10 by code or description…"
        clearable
        v-model="search"
        @input="onSearch"
        class="flex-grow-0"
        :loading="autocompleteLoader"
        :readonly="autocompleteLoader"
      ></v-text-field>

      <div class="text-h6 text-center mb-2">Selected ICD10</div>

      <div class="body-1 text-center" v-if="!selectedIcd">
        You have not selected any ICD10
      </div>

      <template v-else>
        <v-chip
          color="primary"
          class="align-self-center mb-2"
          close
          @click:close="setIcd(null)"
        >
          {{ selectedIcd.option_key }}
        </v-chip>

        <div
          class="align-self-center lighten-1 py-1 white--text px-3 d-flex align-center justify-center text-center rounded icd-description"
        >
          {{ selectedIcd.option_value }}
        </div>
      </template>

      <v-divider class="my-4"></v-divider>

      <div
        class="d-flex flex-column scrollable"
        :class="{ 'flex-grow-1': loader || icd10.length }"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          size="50"
          width="4"
          class="ma-auto"
          v-if="loader"
        ></v-progress-circular>

        <template v-else>
          <div
            class="autocomplete-item align-self-center"
            :class="{ 'mb-4': index + 1 !== icd10.length }"
            v-for="(icd, index) in icd10"
            :key="index"
          >
            <v-row>
              <v-col cols="6">
                <div class="body-1 font-weight-medium mb-2 text-center">
                  {{ icd.option_key }}
                </div>
                <div class="body-2 font-weight-medium text-center">
                  {{ icd.option_value }}
                </div>
              </v-col>

              <v-col cols="6" class="d-flex align-center justify-center">
                <v-btn
                  color="primary"
                  small
                  rounded
                  @click="setIcd(icd)"
                  :disabled="
                    selectedIcd && icd.option_key == selectedIcd.option_key
                  "
                  >select</v-btn
                >
              </v-col>

              <v-col cols="12" v-if="index + 1 !== icd10.length">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
        </template>
      </div>

      <v-divider class="my-4" v-if="loader || icd10.length"></v-divider>

      <v-btn
        color="primary"
        class="align-self-center"
        @click="$emit('closeDialog')"
      >
        done
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      search: "",
      debounce: null,
      loader: false,
      autocompleteLoader: false,
      icd10: [],
    };
  },
  computed: {
    ...mapState({
      selectedIcd: (state) => state.medicalReport.selectedIcd,
    }),
  },
  methods: {
    ...mapActions(["autocomplete"]),
    ...mapMutations(["setIcd"]),
    onSearch(event) {
      if (!event) {
        this.loader = false;
        this.icd10 = [];
        return;
      }

      this.loader = true;
      clearTimeout(this.debounce);

      this.debounce = setTimeout(async () => {
        this.autocompleteLoader = true;

        this.icd10 = await this.autocomplete(event);

        if (!this.search) this.icd10 = [];

        this.autocompleteLoader = false;
        this.loader = false;
      }, 700);
    },
  },
};
</script>

<style lang="scss" scoped>
.component-wrapper {
  min-height: 100%;
}

.autocomplete-item {
  width: 300px;
}
</style>