<template>
  <div>
    <div
      class="toolbar-wrapper py-2 px-5 d-flex justify-center align-center elevation-2"
    >
      <img src="@/assets/logo.svg" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.toolbar-wrapper {
  height: 64px;
  position: relative;

  img {
    height: 100%;
  }
}
</style>