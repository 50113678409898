<template>
  <div
    class="component-wrapper d-flex flex-column justify-center align-center"
    :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
  >
    <div
      class="rounded-b videocall-card d-flex flex-column"
      :class="{
        'elevation-2': !$vuetify.breakpoint.xsOnly,
      }"
    >
      <div
        class="primary text-center pa-2 text-h6 white--text rounded-t mb-5"
        v-if="!$vuetify.breakpoint.xsOnly"
      >
        Room
      </div>

      <div
        class="videocall-card-inner align-self-center"
        :class="{
          'pa-0': $vuetify.breakpoint.xsOnly,
          'px-5': !$vuetify.breakpoint.xsOnly,
        }"
      >
        <div
          class="remote rounded"
          ref="remote"
          :class="{ 'not-connected': !remoteConnected }"
        >
          <div v-if="!connected || !remoteConnected">
            <div
              class="body-1 primary--text text-center mb-1"
              v-if="connected && !remoteConnected"
            >
              Waiting for Master to connect
            </div>

            <div
              class="body-1 primary--text text-center mb-1"
              v-if="!connected"
            >
              Connecting...
            </div>

            <DotLoader />
          </div>

          <div
            class="camera-muted-msg white--text body-1 text-center"
            v-if="!remoteVideoState && remoteConnected"
          >
            The ship captain has temporarily turned off his camera
          </div>
        </div>

        <div class="local rounded" ref="local" v-show="connected">
          <div
            class="camera-muted-msg white--text body-1 text-center"
            v-if="localTracksMuted.video"
          >
            Your camera is off
          </div>
        </div>

        <div class="button-wrapper py-2 d-flex justify-center mt-auto">
          <Buttons />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Buttons from "@/views/Buttons";
import DotLoader from "@/components/DotLoader";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Buttons,
    DotLoader,
  },
  data() {
    return {
      connected: false,
      remoteConnected: false,
    };
  },
  computed: {
    ...mapState({
      room: (state) => state.room.room,
      callData: (state) => state.room.callData,
      localTracksMuted: (state) => state.room.localTracksMuted,
      remoteVideoState: (state) => state.room.remoteVideoState,
    }),
  },
  async mounted() {
    try {
      await this.connectToRoom();
    } catch (e) {
      this.$router.push({ path: "/" });
    }

    this.room.localParticipant.videoTracks.forEach((track) => {
      this.$refs.local.appendChild(track.track.attach());
    });

    this.connected = true;

    //====== HANDLE CONNECTED PARTICIPANTS ======
    this.room.participants.forEach((participant) => {
      console.log(`Participant "${participant.identity}" already connected`);
      this.remoteConnected = true;

      participant.tracks.forEach((publication) => {
        if (publication.track) {
          this.$refs.remote.appendChild(publication.track.attach());
        }
      });

      participant.on("trackSubscribed", (track) => {
        this.$refs.remote.appendChild(track.attach());
      });

      participant.on("trackDisabled", (publication) => {
        if (publication.kind == "video") {
          this.setRemoteVideoState(publication.isTrackEnabled);
        }
      });

      participant.on("trackEnabled", (publication) => {
        if (publication.kind == "video") {
          this.setRemoteVideoState(publication.isTrackEnabled);
        }
      });
    });

    //====== HANDLE REMOTE PARTICIPANTS EVENTS ======

    //------ participant connected ------
    this.room.on("participantConnected", (participant) => {
      console.log(`Participant "${participant.identity}" connected`);
      this.remoteConnected = true;

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          const track = publication.track;
          this.$refs.remote.appendChild(track.attach());
        }
      });

      participant.on("trackSubscribed", (track) => {
        this.$refs.remote.appendChild(track.attach());
      });

      participant.on("trackDisabled", (publication) => {
        if (publication.kind == "video") {
          this.setRemoteVideoState(publication.isTrackEnabled);
        }
      });

      participant.on("trackEnabled", (publication) => {
        if (publication.kind == "video") {
          this.setRemoteVideoState(publication.isTrackEnabled);
        }
      });
    });

    //------ participant disconnected ------
    this.room.on("participantDisconnected", (participant) => {
      console.log(`Participant disconnected: ${participant.identity}`);
    });

    //====== HANDLE ROOM DISCONNECTION ======
    this.room.on("disconnected", (room) => {
      console.log("disconnected from room");

      room.localParticipant.tracks.forEach((publication) => {
        publication.track.stop();
        publication.track.detach();
      });

      this.endCall();
      this.$router.push({ path: "/" });
    });
  },
  methods: {
    ...mapActions(["connectToRoom"]),
    ...mapMutations(["toggleSnackbar", "setRemoteVideoState", "endCall"]),
  },
  destroyed() {
    if (this.remoteConnected) {
      this.toggleSnackbar({
        open: true,
        color: "warning",
        text: "Call Ended.",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.videocall-card {
  @media only screen and (max-width: 599px) {
    width: 100%;
    height: 100%;
  }
}

.videocall-card-inner {
  position: relative;

  @media only screen and (max-width: 599px) {
    width: 100%;
    height: 100%;
  }
}

.local {
  position: absolute;
  right: 16px;
  bottom: 16px;
  height: 120px;
  width: 160px;

  @media only screen and (max-width: 599px) {
    height: 90px;
    width: 120px;
    bottom: 52px;
    right: 8px;
  }
}

.remote {
  width: 640px;
  height: 480px;
  position: relative;

  @media only screen and (min-width: 600px) and (max-width: 720px) {
    width: 480px;
    height: 360px;
  }

  @media only screen and (max-width: 599px) {
    width: 100%;
    height: 100%;
  }

  &.not-connected {
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.button-wrapper {
  @media only screen and (max-width: 599px) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.camera-muted-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>