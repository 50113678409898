import axios from 'axios';

export default {
    state: {
        validationCalled: false,
        isTokenValid: true,
        pemeLink: null
    },
    mutations: {
        setValidationCalled(state, validationCalled) {
            state.validationCalled = validationCalled;
        },
        setTokenValidity(state, isTokenValid) {
            state.isTokenValid = isTokenValid;
        },
        setPemetLink(state, pemeLink) {
            state.pemeLink = pemeLink;
        }
    },
    actions: {
        async getCallData({ commit }, token) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/doctor/shipcall/accept`, {
                    shipCallId: token
                });

                commit("setCallData", res.data);
            } catch (e) {
                commit("setTokenValidity", false);

                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Error on link validation. Please try again with valid link."
                });

                throw e;
            }
        },
        async getPEME({ commit }, token) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/doctor/shipcall/peme`, {
                    shipCallId: token
                });

                commit("setPemetLink", res.data.pemeLink);
            } catch (e) {
                throw e;
            }
        }
    },
}
