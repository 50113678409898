import axios from 'axios';

export default {
    state: {
        snackbar: {
            open: false,
            text: null,
            color: null
        },
        doctorTypes: [],
        doctorTypesFetched: false
    },
    mutations: {
        toggleSnackbar(state, snackbar) {
            state.snackbar = {
                ...state.snackbar,
                ...snackbar
            };
        },
        getDoctorTypes(state, doctorTypes) {
            state.doctorTypes = doctorTypes;
        },
        setDoctorsTypesFetched(state, doctorTypesFetched) {
            state.doctorTypesFetched = doctorTypesFetched;
        },
    },
    actions: {
        async getDoctorTypes({ state, commit }) {
            if (state.doctorTypesFetched) return;

            try {

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/optionsList/doctorTypes/en`);

                commit("getDoctorTypes", res.data);
                commit("setDoctorsTypesFetched", true);

            } catch (e) {
                throw e;
            }
        },
    },
}
