<template>
  <v-app>
    <Navigation />

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar v-model="snackbar.open" :color="snackbar.color" timeout="400000">
      <div class="d-flex align-center">
        <div class="body-2 mr-2">{{ snackbar.text }}</div>
        <v-btn text small class="ml-auto" @click="onCloseSnackbar">Close</v-btn>
      </div>
    </v-snackbar>

    <Footer />
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

import { mapState, mapMutations } from "vuex";

export default {
  components: {
    Navigation,
    Footer,
  },
  computed: {
    ...mapState({
      snackbar: (state) => state.base.snackbar,
    }),
  },
  async created() {
    // ====== MOBILE BROWSER SCROLLING STUFF =======
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  },
  methods: {
    ...mapMutations(["toggleSnackbar"]),
    onCloseSnackbar() {
      this.toggleSnackbar({
        open: false,
      });
    },
  },
};
</script>

<style lang="scss">
//====== VUETIFY OVERRIDES ======
.v-application--wrap {
  height: 1px;
  min-height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100) !important;
}

.v-main__wrap {
  display: flex;
}

//====== GLOBAL STYLES ======

.component-wrapper {
  flex-grow: 1;
  padding: 20px;
}

.inner-wrapper {
  width: 868px;

  @media only screen and (max-width: 908px) {
    width: 100%;
  }
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  height: 0;
  min-height: 0;
}

.icd-description {
  border-left: 6px solid var(--v-primary-base);
  background-color: var(--v-secondary-lighten4);
}

.local,
.remote {
  overflow: hidden;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
