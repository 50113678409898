import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Home from '../views/Home.vue'
import Room from '../views/Room.vue'
import MedicalReport from '../views/MedicalReport.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location)
    .catch(err => {
      if (err.name != "NavigationDuplicated") {
        throw err;
      }
    })
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/room',
    name: 'room',
    component: Room,
    beforeEnter: (to, from, next) => {
      if (store.state.validation.validationCalled && store.state.validation.isTokenValid) {
        next();
      } else {
        next({ path: "/" })
      }
    }
  },
  {
    path: '/medical-report/:id',
    name: 'medicalReport',
    component: MedicalReport
  },
  {
    path: "*",
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
