import twilio from "twilio-video";
import axios from 'axios';

export default {
    state: {
        localTracks: null,
        room: null,
        localTracksMuted: {
            video: false,
            audio: false
        },
        remoteVideoState: true,
        facingMode: 'user',
        callEnded: false,
    },
    mutations: {
        setCallData(state, callData) {
            state.callData = callData;
        },
        setLocalTracks(state, localTracks) {
            state.localTracks = localTracks;
        },
        setRoom(state, room) {
            state.room = room;
        },
        changeTrackState(state, payload) {
            state.localTracksMuted[payload.kind] = payload.action == 'mute' ? true : false;
        },
        setRemoteVideoState(state, remoteVideoState) {
            state.remoteVideoState = remoteVideoState;
        },
        toggleFacingMode(state) {
            state.facingMode = state.facingMode == "user" ? "environment" : "user";
        },
        endCall(state) {
            state.callEnded = true;
        }
    },
    actions: {
        async getLocalTracks({ commit }) {
            try {
                const tracks = await twilio.createLocalTracks({
                    video: { width: 1920 },
                    audio: true,
                });

                commit('setLocalTracks', tracks);
            } catch (e) {
                throw e;
            }
        },
        async connectToRoom({ commit, dispatch, state }) {
            try {
                await dispatch("getLocalTracks");

                const room = await twilio.connect(state.callData.doctorTwilioToken,
                    {
                        name: state.callData.shipRoomSid,
                        tracks: state.localTracks
                    }
                );

                commit('setRoom', room);
            } catch (e) {
                if (e?.message == "getLocalTracks") {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: "Error while opening the camera."
                    })
                } else {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: "Could not connect to room."
                    })
                }

                throw e;
            }
        },
        changeTrackState({ state, commit }, payload) {
            state.room.localParticipant.tracks.forEach((publication) => {
                if (publication.kind == payload.kind) {
                    if (payload.action == 'mute') {
                        publication.track.disable();
                    } else {
                        publication.track.enable();
                    }
                    commit('changeTrackState', payload);
                }
            })
        },
        switchCamera({ commit, state }) {
            state.localTracks.forEach((track) => {
                if (track.kind == "video") {
                    track.stop();
                }
            });

            commit("toggleFacingMode");

            const cameraTrack = state.localTracks.find((track) => track.kind === "video");

            cameraTrack.restart({ facingMode: state.facingMode });
        },
        async endCall({ state }) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/doctor/shipcall/end`, {
                    shipCallRoom: state.callData.shipCallRoom,
                });
            } catch (e) {
                throw e;
            }
        },
    },
}
