import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
        light: {
            primary: '#00a9dc',
            accent: '#71d230'
        }
    },
    options: {
        customProperties: true,
    }
},
});
