<template>
  <div class="component-wrapper d-flex flex-column">
    <div class="text-h6 text-center mb-6">Medical Report</div>

    <v-alert
      border="top"
      color="warning lighten-1"
      dark
      v-if="!medicalReportLoader && medicalReport && medicalReport.isFinal"
      class="align-self-center"
    >
      Medical report has been submitted. It has been switched to read only mode.
    </v-alert>

    <v-progress-circular
      indeterminate
      color="primary"
      size="50"
      width="4"
      class="ma-auto"
      v-if="medicalReportLoader"
    ></v-progress-circular>

    <form
      v-if="!medicalReportLoader && medicalReport"
      @submit.prevent="submit"
      class="inner-wrapper d-flex flex-column align-self-center"
    >
      <MetaData class="mb-8" />

      <!-- ====== A. Diagnosis / First Estimate ====== -->
      <div
        class="subtitle-1 font-weight-medium"
        :class="{ 'error--text': diagnosisError }"
      >
        A. Diagnosis / First Estimate*
      </div>

      <div class="body-2 mb-4">
        Describe patient’s symptoms, the examination you performed and your
        diagnosis in as much detail as possible.
      </div>

      <v-textarea
        label="Describe here…"
        no-resize
        class="align-self-stretch flex-grow-0 mb-4"
        rows="2"
        outlined
        hide-details
        v-model="diagnosisText"
        :error="diagnosisError"
        :readonly="medicalReport.isFinal"
      ></v-textarea>

      <div class="body-2 mb-2">Please select ICD10 code</div>
      <v-btn
        small
        color="primary"
        class="align-self-start mb-4"
        @click="icdDialog = true"
        :disabled="medicalReport.isFinal"
        >{{ selectedIcd ? "change" : "add" }} ICD10</v-btn
      >

      <div class="subtitle-1 mb-2">Selected ICD10</div>

      <div class="body-2" v-if="!selectedIcd">
        You have not selected any ICD10
      </div>

      <template v-else>
        <v-chip
          color="primary"
          class="align-self-start white--text mb-2"
          :close="!medicalReport.isFinal"
          @click:close="setIcd(null)"
        >
          {{ selectedIcd.option_key }}
        </v-chip>

        <div
          class="align-self-start lighten-1 py-1 white--text px-3 d-flex align-center justify-center text-center rounded icd-description"
        >
          {{ selectedIcd.option_value }}
        </div>
      </template>

      <v-divider class="my-5"></v-divider>

      <!-- ====== B. Further examinations ====== -->

      <div class="subtitle-1 font-weight-medium mb-4">
        B. Further examinations
      </div>

      <v-btn
        small
        color="primary"
        class="align-self-start mb-4"
        @click="examinationDialog = true"
        :disabled="medicalReport.isFinal"
        >Add Examinations</v-btn
      >

      <div class="subtitle-1 mb-2">Selected Examinations</div>

      <div class="body-2" v-if="!selectedExams.length">
        You have not selected any examination
      </div>

      <div class="d-flex flex-wrap">
        <div class="pa-1" v-for="exam in selectedExams" :key="exam.option_key">
          <v-chip
            color="primary"
            :close="!medicalReport.isFinal"
            @click:close="removeExam(exam)"
          >
            {{ exam.option_value }}
          </v-chip>
        </div>
      </div>

      <v-divider class="my-5"></v-divider>

      <!-- ====== C. Treatment / Medication ====== -->

      <div class="subtitle-1 font-weight-medium mb-4">
        C. Treatment / Medication*
      </div>

      <v-textarea
        label="Describe here…"
        no-resize
        class="align-self-stretch flex-grow-0"
        rows="2"
        outlined
        hide-details
        v-model="treatmentText"
        :error="treatmentError"
        :readonly="medicalReport.isFinal"
      ></v-textarea>

      <v-divider class="my-5"></v-divider>

      <!-- ====== D. Instructions / Notes ====== -->

      <div class="subtitle-1 font-weight-medium mb-4">
        D. Instructions / Notes
      </div>

      <v-textarea
        label="Describe here…"
        no-resize
        class="align-self-stretch flex-grow-0 mb-4"
        rows="2"
        outlined
        hide-details
        v-model="instructionsText"
        :readonly="medicalReport.isFinal"
      ></v-textarea>

      <!-- ====== E. Refer to a different speciality if necessary ====== -->
      <div class="subtitle-1 font-weight-medium mb-4">
        E. Refer to a different speciality if necessary
      </div>

      <v-select
        outlined
        dense
        hide-details
        class="align-self-stretch flex-grow-0 mb-4"
        :items="doctorTypes"
        item-text="option_value"
        item-value="option_key"
        :loading="doctorTypesLoader"
        label="Refer to"
        v-model="doctorType"
        clearable
      >
      </v-select>

      <div class="body-1 mb-2">Is case closed?</div>

      <v-radio-group
        v-model="isClosed"
        row
        class="mt-0"
        :disabled="!!doctorType"
      >
        <v-radio label="Yes" value="yes"></v-radio>
        <v-radio label="No" value="no"></v-radio>
      </v-radio-group>

      <div class="d-flex justify-center mt-5">
        <v-btn
          class="mr-2"
          color="primary"
          type="submit"
          :disabled="medicalReport.isFinal"
          >Submit</v-btn
        >

        <v-btn
          color="primary"
          outlined
          type="button"
          @click="saveAsDraft"
          :loading="submitLoader && !confirmDialog"
          :disabled="medicalReport.isFinal"
          >Save as draft</v-btn
        >
      </div>
    </form>

    <!-- ====== DIALOGS ====== -->

    <!-- ====== A. Diagnosis / First Estimate ====== -->
    <v-dialog v-model="icdDialog" fullscreen>
      <ICD10 v-if="icdDialog" @closeDialog="icdDialog = false" />
    </v-dialog>

    <!-- ====== B. Further examinations ====== -->
    <v-dialog v-model="examinationDialog" fullscreen>
      <Examinations
        v-if="examinationDialog"
        @closeDialog="examinationDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="confirmDialog" width="500">
      <div class="white pa-4">
        <div class="body-1 text-center mb-4">
          Are you sure you want to proceed with final submission? You will not
          be able to submit new changes.
        </div>

        <div class="d-flex justify-center">
          <v-btn
            color="primary"
            class="mr-2"
            small
            @click="finalSubmit"
            :loading="submitLoader"
            >Submit</v-btn
          >

          <v-btn color="error" small @click="confirmDialog = false"
            >Cancel</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import MetaData from "@/views/MetaData";
import ICD10 from "@/views/ICD10";
import Examinations from "@/views/Examinations";

import { required } from "vuelidate/lib/validators";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: {
    MetaData,
    ICD10,
    Examinations,
  },
  data() {
    return {
      icdDialog: false,
      examinationDialog: false,
      diagnosisText: "",
      treatmentText: "",
      instructionsText: "",
      medicalReportLoader: false,
      confirmDialog: false,
      submitLoader: false,
      doctorTypesLoader: false,
      doctorType: null,
      isClosed: "yes",
    };
  },
  computed: {
    ...mapState({
      medicalReport: (state) => state.medicalReport.medicalReport,
      selectedIcd: (state) => state.medicalReport.selectedIcd,
      selectedExams: (state) => state.medicalReport.selectedExams,
      doctorTypes: (state) => state.base.doctorTypes,
    }),
    diagnosisError() {
      return this.$v.$dirty && this.$v.diagnosisText.$invalid;
    },
    treatmentError() {
      return this.$v.$dirty && this.$v.treatmentText.$invalid;
    },
  },
  validations: {
    diagnosisText: { required },
    treatmentText: { required },
  },
  async created() {
    this.doctorTypesLoader = true;
    await this.getDoctorTypes();
    this.doctorTypesLoader = false;

    try {
      this.medicalReportLoader = true;
      await this.getMedicalReport(this.$route.params.id);
      this.medicalReportLoader = false;
    } catch (e) {
      this.$router.push({ path: "/" });
    }

    console.log(this.medicalReport);

    this.diagnosisText = this.medicalReport?.diagnosisText;
    this.treatmentText = this.medicalReport?.treatmentText;
    this.instructionsText = this.medicalReport?.instructionsText;
    this.doctorType = this.medicalReport?.referralSpecialityId;
    this.isClosed = this.medicalReport?.closesShipCase ? "yes" : "no";

    if (this.medicalReport?.iCD10Code && this.medicalReport?.iCD10Description) {
      this.setIcd({
        option_key: this.medicalReport?.iCD10Code,
        option_value: this.medicalReport?.iCD10Description,
      });
    }

    this.selectExams(
      this.medicalReport?.furtherExaminations.map((e) => {
        return {
          option_key: e.examinationId,
          option_value: e.examinationName,
        };
      })
    );
  },
  methods: {
    ...mapActions([
      "getMedicalReport",
      "submitMedicalReport",
      "getDoctorTypes",
    ]),
    ...mapMutations(["setIcd", "selectExams", "removeExam"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.confirmDialog = true;
    },
    async finalSubmit() {
      const furtherExaminations = this.selectedExams.map((e) => {
        return {
          examinationId: e.option_key,
        };
      });

      this.submitLoader = true;

      await this.submitMedicalReport({
        shipCallId: this.medicalReport.shipCallId,
        medicalReportId: this.medicalReport.medicalReportId,
        diagnosisText: this.diagnosisText,
        iCD10Code: this.selectedIcd?.option_key,
        furtherExaminations,
        treatmentText: this.treatmentText,
        instructionsText: this.instructionsText,
        isFinal: true,
        referralSpecialityId: this.doctorType,
        closesShipCase: this.isClosed == "yes" ? true : false,
      });

      this.submitLoader = false;
      this.confirmDialog = false;

      this.$vuetify.goTo(0);
    },
    async saveAsDraft() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const furtherExaminations = this.selectedExams.map((e) => {
        return {
          examinationId: e.option_key,
        };
      });

      this.submitLoader = true;

      await this.submitMedicalReport({
        shipCallId: this.medicalReport.shipCallId,
        medicalReportId: this.medicalReport.medicalReportId,
        diagnosisText: this.diagnosisText,
        iCD10Code: this.selectedIcd?.option_key,
        furtherExaminations,
        treatmentText: this.treatmentText,
        instructionsText: this.instructionsText,
        isFinal: false,
        referralSpecialityId: this.doctorType,
        closesShipCase: this.isClosed == "yes" ? true : false,
      });

      this.submitLoader = false;

      this.$vuetify.goTo(0);
    },
  },
  watch: {
    doctorType(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.isClosed = "no";
      }

      if (!newValue && oldValue) {
        this.isClosed = "yes";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>