<template>
  <div class="d-flex flex-column">
    <Loader v-if="loader" />

    <v-btn
      large
      color="primary"
      @click="onGetCallData"
      v-else
      class="align-self-center mb-5"
      width="184px"
    >
      Enter Video Call
    </v-btn>

    <v-btn
      small
      color="primary"
      class="align-self-center mb-2"
      width="184px"
      v-if="!pemeError"
      :loading="pemeLoader"
      @click="downloadPEME"
    >
      PEME
    </v-btn>

    <div class="body-2 text-center align-self-center mb-5" v-if="!pemeError">
      (Pre Employment Medical Examination)
    </div>

    <!-- <v-alert dense text type="error" v-if="!isSupported">
      You browser <strong>does not support</strong> live videocall.
    </v-alert>

    <v-alert dense text type="warning" v-if="!isChrome || !isSupported">
      The <strong>recommended</strong> Browser is the latest version of
      <strong>Google Chrome</strong>.
    </v-alert>

    <v-btn
      class="align-self-center"
      color="warning"
      small
      @click="openChrome"
      v-if="(!isChrome || !isSupported) && operatingSystem == 'android'"
    >
      Open Google Chrome
    </v-btn> -->
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import { mapActions, mapMutations, mapState } from "vuex";
import { isSupported } from "twilio-video";
import { Plugins } from "@capacitor/core";

const { Device } = Plugins;

export default {
  components: {
    Loader,
  },
  data() {
    return {
      loader: false,
      isSupported,
      // isChrome: navigator.userAgent.includes("Chrome"),
      operatingSystem: null,
      pemeLoader: false,
      pemeError: false,
    };
  },
  computed: {
    ...mapState({
      pemeLink: (state) => state.validation.pemeLink,
    }),
  },
  async created() {
    const info = await Device.getInfo();

    this.operatingSystem = info.operatingSystem;

    this.pemeLoader = true;

    try {
      await this.getPEME(this.$route.query.token);
      this.pemeLoader = false;
    } catch (e) {
      this.pemeError = true;
      this.pemeLoader = false;
    }
  },
  methods: {
    ...mapActions(["getCallData", "getPEME"]),
    ...mapMutations(["setValidationCalled"]),
    onGetCallData() {

      if (!this.isSupported) {
        this.openChrome();
      } else {
        this.loader = true;

        this.getCallData(this.$route.query.token)
          .then(() => {
            this.loader = false;
            this.setValidationCalled(true);
            this.$router.push({ path: "/room" });
          })
          .catch(() => {
            this.loader = false;
            this.setValidationCalled(true);
          });
      }
    },
    async openChrome() {
      let hostUrl = 'dn2me-shipcaredoctor-development.code-factory.gr';

      let url = `${hostUrl}/?token=${this.$route.query.token}`;

      location.href = `intent://${url}#Intent;scheme=https;package=com.android.chrome;end`;

      // window.open(
      //   "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.android.chrome",
      //   "_system"
      // );
    },
    downloadPEME() {
      window.open(this.pemeLink, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>