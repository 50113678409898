<template>
  <div class="footer-wrapper d-flex flex-column align-center pb-2 px-5">
    <v-divider class="align-self-stretch mb-2"></v-divider>

    <div class="body-2 primary--text">&copy; {{ year }} DN2ME</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.toolbar-wrapper {
  height: 100px;

  img {
    height: 100%;
  }
}
</style>